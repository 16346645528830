var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Technology"},[_vm._m(0),_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,style:({
      background:
        index == 0
          ? '#3B659E'
          : index == 1
          ? '#597AB2'
          : index == 2
          ? '#6B8CC4'
          : '',
    })},[_c('div',{staticClass:"background"},[_c('div',{staticClass:"box",class:[
          index == 0
            ? 'transparency1'
            : index == 1
            ? 'transparency2'
            : index == 2
            ? 'transparency3'
            : '' ]},[(index % 2 != 0)?_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"space-between"},on:{"click":function($event){return _vm.play(index)}}},[_c('div',{staticClass:"textBox"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.text))])]),_c('div',{staticClass:"video"},[_c('video',{staticClass:"video",attrs:{"src":item.videoUrl,"controls":"controls","loop":"loop","preload":"preload"}})])]):_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"space-between"},on:{"click":function($event){return _vm.play(index)}}},[_c('div',{staticClass:"video",on:{"click":function($event){return _vm.play(index)}}},[_c('video',{staticClass:"video",attrs:{"src":item.videoUrl,"controls":"controls","loop":"loop","preload":"preload"}})]),_c('div',{staticClass:"textBox"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.text))])])])])])])}),_c('div',{staticClass:"foot"},_vm._l((_vm.bottomList),function(item,index){return _c('div',{key:index,staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"32px","height":"32px"},attrs:{"alt":"Vue logo","src":item.url}}),_c('span',{staticClass:"textStyle"},[_vm._v(_vm._s(item.text))])])}),0)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","height":"80px","background":"rgb(52, 126, 240)","display":"flex"}},[_c('div',{staticClass:"navBox"}),_c('div',{staticClass:"navText"},[_vm._v("植发技术")])])}]

export { render, staticRenderFns }